import api from '@/plugins/axios'

const base = 'v1/investidor/'

export default {
    getIncomes() {
        return api.get(base + 'informe-rendimentos/')
    },
    getIncome(ano) {
        return api.get(base + 'informe-rendimentos-pdf', { params: { ano }, responseType: 'blob' })
    },
    getExtract(ano) {
        return api.get(base + 'extrato-investimentos/', { params: { ano }, responseType: 'blob' })
    }
}
