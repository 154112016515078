<template>
  <div class="content row">
    <div class="content p-24 mt-4 w-full flex flex-col lg:flex-row lg:flex-wrap" v-if="loading">
      <div v-if="loading" class="h-full w-full flex justify-center items-center text-center">
          <i class="fas fa-spinner fa-spin text-2xl text-secondary"></i>
        </div>
    </div>

    <div class="pt-5 grid grid-cols-1 md:grid-cols-2  gap-5" v-if="!loading && data != null">
        <!--Card 1-->
        <div class="rounded overflow-hidden shadow-lg card">
          <div class="px-6 py-4">
            <div class="text-sm mb-2 text text-gray-900 font-semibold	">SEUS INVESTIMENTOS</div>
              <div class="grid grid-rows-4 grid-flow-col text-gray-800 text-sm">
                <div class="col-start-1">Valor Investido</div>
                <div class="col-start-7">{{data.valorInvestido | money}}</div>

                <div class="col-start-1">Número de investimentos</div>
                <div class="col-start-7">{{data.numeroInvestimentos}}</div>

                <div class="col-start-1">Exposição máxima</div>
                <div class="col-start-7">{{data.exposicaoMaxima | money}}</div>

                <div class="col-start-1">Valor Recebido</div>
                <div class="col-start-7">{{data.valorRecebido | money}}</div>
              </div>
          </div>
        </div>


        <div class="rounded overflow-hidden shadow-lg card">
          <div class="px-6 py-4">
            <div class="text-sm mb-2 text text-gray-900 font-semibold	">RENTABILIDADE DA SUA CARTEIRA</div>

              <div class="grid grid-rows-4 grid-flow-col text-gray-800 text-sm">
                <div class="col-start-1">Taxa Interna de Retorno ao Ano</div>
                <div class="col-start-7">{{data.tir.toString().replace('.',',')}}%</div>

                <div class="col-start-1">Rentabilidade</div>
                <div class="col-start-7">{{data.rentabilidade.toString().replace('.',',')}}%</div>

                <div class="col-start-1">Taxa de Inadimplência</div>
                <div class="col-start-7">{{data.taxaInadimplencia.toString().replace('.',',')}}%</div>
              </div>
              <button :disabled="downloading" v-on:click="gerarPlanilha" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-xs px-4 py-2 rounded inline-flex items-center">

               <i v-show="downloading" class="fas fa-spinner fa-spin text-2xl text-secondary"></i> &emsp;
               {{(downloading ? 'Gerando planilha, aguarde.' : 'Exportar em planilha do Excel')}}
              </button>
           </div>

        </div>

        <div class="rounded overflow-hidden shadow-lg card  ">
          <div class="px-6 py-4">
            <div class="text-sm mb-2 text text-gray-900 font-semibold	">DISTRIBUIÇÃO DA CARTEIRA</div>

              <bar-chart
              class="chart-container"
              :data="getDataDistribuicaoCarteira()"
              :options="barOptions"
            />
           </div>

        </div>

        <div class="rounded overflow-hidden shadow-lg card  ">
          <div class="px-6 py-4">
            <div class="text-sm mb-2 text text-gray-900 font-semibold	">ESTADO ATUAL DOS SEUS INVESTIMENTOS</div>

              <pie-chart
              class="chart-container"
              :data="getDataEstadoAtualEmprestimos()"
              :options="pieOptions"
            />
           </div>

        </div>

        </div>
  </div>
</template>

<script>
import moneyFilter from '@/mixins/moneyFilter'
import Dashboard from '@/services/Investor/Dashboard'
import BarChart from "@/components/Charts/BarChart";
import PieChart from "@/components/Charts/PieChart";

export default {
  name: 'visao-geral',
  mixins: [moneyFilter],
  components: {BarChart, PieChart},
  data () {
    return {
      data : null,
      loading: false,
      downloading: false
    }
  },
  methods: {
    async gerarPlanilha(){
      this.downloading = true;
       const url = window.URL.createObjectURL(new Blob([(await Dashboard.generateExcel()).data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Fluxo.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloading = false;
    },
    getDataDistribuicaoCarteira() {
      const data = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: "rgba(113, 193, 82, 0.7)",
            label: "Rating"
          }
        ]
      };

      Object.entries(this.data.distribuicaoRatingDTO).forEach(([key, value]) => {
          data.labels.push(`${value.rating}`);
          data.datasets[0].data.push(value.qtde);
      });
      return data;
    },

    getDataEstadoAtualEmprestimos() {
      const data = {
        labels: ['Em dia',
        '1 a 9 dias de atraso',
        '10 a 30 dias de atraso',
        '31 a 59 dias de atraso',
        '60 a 90 dias de atraso',
        'Acima de 90 dias de atraso'
        ],
        datasets: [
          {
            data: [],
                backgroundColor: [
              "rgba(113, 193, 82, 0.7)",
              "rgba(238, 238, 72, 0.7)",
              "rgba(60, 179, 208, 0.7)",

              "rgba(192, 71, 207, 0.7)",
              "rgba(95, 6, 106, 0.7)",
              "rgba(171, 0, 55, 0.7)",

              ],
            label: "Rating"
          }
        ]
      };

        Object.entries(this.data.estadoAtualEmprestimos).forEach(([key, value]) => {
          console.log(key + ':' + value)
          data.datasets[0].data.push(value);
      });

      return data;
    },
 },
 async mounted () {
   this.loading = true;
   this.data = (await Dashboard.getVisaoGeral()).data.data;
   console.log(this.data)
   this.loading = false;
  },

  computed: {
    barOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        beginAtZero: true,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                        beginAtZero: true,
                        callback: function (value) { if (Number.isInteger(value)) { return value; } },
                        stepSize: 1
                      }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return (tooltipItem.yLabel);
            }
          }
        }
      };
    },
    pieOptions() {
      return {
            title: {
              display: true,
              text: 'Por número de empresas',
            },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return `${data.labels[tooltipItem.index] || ""}: ${
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              }%`;
            }
          }
        },
        legend: {
          position: 'bottom'
        }
      };
    },  }
}
</script>

<style lang="sass" scoped>


.card
  background-color: #fff
  width: 100%
  @screen md
    width: 420px
  .small
    font-size: 60%
    text-align: right



</style>
