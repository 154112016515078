<template>
  <div class="content">
        <invoice-modal v-if="showInvoiceModal" :invoice="invoice" @closeModal="showInvoiceModal = false"/>

    <div class="w-full overflow-x-auto lg:overflow-x-hidden">
      <vuetable ref="vuetable"
        class="repayments_table"
        :fields="header"
        :api-mode="false"
        :data-manager="dataManager"
        :no-data-template="loading ? '' : 'Nenhum dado retornado'"
      >
        <template v-slot:Empresa="props">
          <p class="whitespace-no-wrap uppercase">{{props.rowData.empresa}}</p>
        </template>
        <template v-slot:Vencimento="props">
          {{props.rowData.vencimento}}
        </template>
        <template v-slot:Valor="props">
          {{props.rowData.valor | money}}
        </template>
        <template v-slot:Tipo="props">
           {{props.rowData.tipoy}}

        </template>
        <template v-slot:Status="props">
         <span v-if="props.rowData.status != 'Ver Fatura'"> {{props.rowData.status}}</span>
        <span v-if="props.rowData.status  == 'Ver Fatura'"> <a href="javascript://" v-on:click="setInvoice(props.rowData.fatura)">{{props.rowData.status}}</a></span>

        </template>
      </vuetable>
    </div>
    <div v-if="loading" class="h-full w-full flex justify-center items-center">
      <i class="fas fa-spinner fa-spin text-2xl text-secondary"></i>
    </div>
    <div class="flex justify-end">
      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
        :css="paginationCss"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import { VuetablePagination } from 'vuetable-2'
import Invoices from '@/services/Investor/Invoices/Invoices'
import moneyFilter from '@/mixins/moneyFilter'
import InvoiceModal from '@/components/Investor/Modal/InvoiceModal'

export default {
  name: 'invoices',
  mixins: [moneyFilter],
  components: { VuetablePagination, InvoiceModal },
  data () {
    return {
      loading: true,
      invoices: [],
      showInforDate: false,
      pagination: {
        current_page: 0,
        last_page: 0,
        per_page: 10,
        total: 0
      },
      header: [
        {
          name: '__slot:Empresa',
          title: 'Empresa'
        },
        {
          name: '__slot:Vencimento',
          title: 'Vencimento'
        },
        {
          name: '__slot:Valor',
          title: 'Valor'
        },
        {
          name: '__slot:Tipo',
          title: 'Tipo'
        },
        {
          name: '__slot:Status',
          title: 'Status'
        }
      ],
      paginationCss: {
        wrapperClass: 'border rounded-lg flex m-2 text-secondary',
        activeClass: 'text-primary shadow-inner bg-gray-100 font-bold',
        disabledClass: 'text-gray-300 cursor-not-allowed',
        pageClass: 'pagination_page_class',
        linkClass: 'pagination_page_class',
        icons: {
          first: 'fas fa-angle-double-left',
          prev: 'fas fa-angle-left',
          next: 'fas fa-angle-right',
          last: 'fas fa-angle-double-right'
        }
      },
      invoice: null,
      showInvoiceModal: false
    }
  },
  computed: {

  },
  methods: {
    setInvoice(invoice){
      this.invoice = invoice;
      this.showInvoiceModal = true;
    },
    onChangePage (page) {
      let reload = false
      switch (true) {
        case page === 'next':
          if (!this.$refs.pagination.isOnLastPage) {
            this.pagination.current_page = this.pagination.current_page + 1
            reload = true
          }
          break
        case page === 'prev':
          if (!this.$refs.pagination.isOnFirstPage) {
            this.pagination.current_page = this.pagination.current_page - 1
            reload = true
          }
          break
        default:
          if (this.pagination.current_page !== page) {
            this.pagination.current_page = page
            reload = true
          }
          break
      }
      if (reload) {
        this.$refs.vuetable.reload()
      }
    },
    dataManager (sortOrder, pagination) {
      if (this.invoices.length < 1) return

      const from = (this.pagination.current_page - 1) * this.pagination.per_page
      const to = from + this.pagination.per_page

      return {
        pagination: this.pagination,
        data: this.invoices.slice(from, to)
      }
    },
    async getInvoices () {
      try {
        this.loading = true
        this.$refs.vuetable.resetData()
        this.showInforDate = false
        this.invoices = (await Invoices.getInvoices()).data.data

        this.pagination = {
          current_page: 1,
          last_page: Math.ceil(this.invoices.length / this.pagination.per_page),
          total: this.invoices.length,
          per_page: this.pagination.per_page
        }
        this.$refs.vuetable.reload()
        this.$refs.pagination.setPaginationData(this.pagination)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    await this.getInvoices()
  }
}
</script>

<style lang="sass" scoped>
.content
  @apply w-full h-full bg-white shadow border rounded-lg mt-6 flex flex-col
  min-height: 10rem

.button
  @apply bg-gray-200 rounded-full text-gray-600 text-sm shadow-md h-8 m-3 px-3 text-gray-600 opacity-75
  &:focus
    @apply outline-none

.close
  @apply text-secondary cursor-pointer
  @screen lg
    @apply mx-1
</style>

<style lang="sass">
.repayments_table
  @apply w-full bg-white shadow rounded-lg
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    &:nth-child(even)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    th
      @apply p-2 text-sm text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-sm border border-gray-300 py-1

.pagination_page_class
  @apply h-8 w-8 border-l flex justify-center items-center cursor-pointer
  &:first-of-type
    @apply border-l-0 rounded-l-lg
  &:last-of-type
    @apply rounded-r-lg
  &:hover
    @apply bg-gray-100
    &.cursor-not-allowed
      @apply bg-transparent
</style>
