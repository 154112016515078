<template>
  <div class="content">
    <div class="w-full flex justify-end items-center">
      <p v-show="showInforDate" class="text-gray-600 text-sm">{{ `${formattedDates.start} - ${formattedDates.end}` }}</p>
      <span v-show="showInforDate" class="close" @click="getRepayments(false)">
        <i class="fas fa-times text-sm"></i>
      </span>
      <v-date-picker
        mode="range"
        v-model="dates"
        @input="getRepayments(true)"
        :max-date="new Date()"
        :show-day-popover="false"
        :popover-align="'right'"
        tint-color="#5316CD"
      >
        <button class="button">
          <i class="fas fa-calendar-alt text-secondary mr-1"></i>Filtar por data
        </button>
      </v-date-picker>
    </div>
    <div class="w-full overflow-x-auto lg:overflow-x-hidden">
      <vuetable ref="vuetable"
        class="repayments_table"
        :fields="header"
        :api-mode="false"
        :data-manager="dataManager"
        :no-data-template="loading ? '' : 'Nenhum dado retornado'"
      >
        <template v-slot:dataPagamento="props">
          {{$moment(props.rowData.dataPagamento).format('DD/MM/YYYY')}}
        </template>
        <template v-slot:nomeOfertaInvestimento="props">
          <p class="whitespace-no-wrap uppercase">{{props.rowData.nomeOfertaInvestimento}}</p>
        </template>
        <template v-slot:numeroParcela="props">
          {{props.rowData.numeroParcela}}
        </template>
        <template v-slot:prestacao="props">
          {{props.rowData.prestacao | money}}
        </template>
        <template v-slot:valorCreditado="props">
          {{props.rowData.valorCreditado | money}}
        </template>
      </vuetable>
    </div>
    <div v-if="loading" class="h-full w-full flex justify-center items-center">
      <i class="fas fa-spinner fa-spin text-2xl text-secondary"></i>
    </div>
    <div class="flex justify-end">
      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
        :css="paginationCss"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import { VuetablePagination } from 'vuetable-2'
import Repayments from '@/services/Investor/Reports/Repayments'
import moneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'repayments',
  mixins: [moneyFilter],
  components: { VuetablePagination },
  data () {
    return {
      loading: true,
      repayments: [],
      dates: { start: this.$moment(), end: this.$moment() },
      showInforDate: false,
      pagination: {
        current_page: 0,
        last_page: 0,
        per_page: 10,
        total: 0
      },
      header: [
        {
          name: '__slot:dataPagamento',
          title: 'Data'
        },
        {
          name: '__slot:nomeOfertaInvestimento',
          title: 'Empresa'
        },
        {
          name: '__slot:numeroParcela',
          title: 'Parcela'
        },
        {
          name: '__slot:prestacao',
          title: 'Prestação'
        },
        {
          name: '__slot:valorCreditado',
          title: 'Valor Creditado'
        }
      ],
      paginationCss: {
        wrapperClass: 'border rounded-lg flex m-2 text-secondary',
        activeClass: 'text-primary shadow-inner bg-gray-100 font-bold',
        disabledClass: 'text-gray-300 cursor-not-allowed',
        pageClass: 'pagination_page_class',
        linkClass: 'pagination_page_class',
        icons: {
          first: 'fas fa-angle-double-left',
          prev: 'fas fa-angle-left',
          next: 'fas fa-angle-right',
          last: 'fas fa-angle-double-right'
        }
      }
    }
  },
  computed: {
    formattedDates () {
      return {
        start: this.$moment(this.dates.start).format('DD/MM/YYYY'),
        end: this.$moment(this.dates.end).format('DD/MM/YYYY')
      }
    }
  },
  methods: {
    onChangePage (page) {
      let reload = false
      switch (true) {
        case page === 'next':
          if (!this.$refs.pagination.isOnLastPage) {
            this.pagination.current_page = this.pagination.current_page + 1
            reload = true
          }
          break
        case page === 'prev':
          if (!this.$refs.pagination.isOnFirstPage) {
            this.pagination.current_page = this.pagination.current_page - 1
            reload = true
          }
          break
        default:
          if (this.pagination.current_page !== page) {
            this.pagination.current_page = page
            reload = true
          }
          break
      }
      if (reload) {
        this.$refs.vuetable.reload()
      }
    },
    dataManager (sortOrder, pagination) {
      if (this.repayments.length < 1) return

      const from = (this.pagination.current_page - 1) * this.pagination.per_page
      const to = from + this.pagination.per_page

      return {
        pagination: this.pagination,
        data: this.repayments.slice(from, to)
      }
    },
    async getRepayments (isDateRange) {
      try {
        this.loading = true
        this.$refs.vuetable.resetData()
        this.showInforDate = false
        this.repayments = (await Repayments.getRepayments(isDateRange ? this.formattedDates : { start: '', end: '' })).data.data
        if (!isDateRange) {
          this.dates = { start: this.$moment(), end: this.$moment() }
        } else {
          this.showInforDate = true
        }
        this.pagination = {
          current_page: 1,
          last_page: Math.ceil(this.repayments.length / this.pagination.per_page),
          total: this.repayments.length,
          per_page: this.pagination.per_page
        }
        this.$refs.vuetable.reload()
        this.$refs.pagination.setPaginationData(this.pagination)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    await this.getRepayments()
  }
}
</script>

<style lang="sass" scoped>
.content
  @apply w-full h-full bg-white shadow border rounded-lg mt-6 flex flex-col
  min-height: 10rem

.button
  @apply bg-gray-200 rounded-full text-gray-600 text-sm shadow-md h-8 m-3 px-3 text-gray-600 opacity-75
  &:focus
    @apply outline-none

.close
  @apply text-secondary cursor-pointer
  @screen lg
    @apply mx-1
</style>

<style lang="sass">
.repayments_table
  @apply w-full bg-white shadow rounded-lg
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    &:nth-child(even)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    th
      @apply p-2 text-sm text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-sm border border-gray-300 py-1

.pagination_page_class
  @apply h-8 w-8 border-l flex justify-center items-center cursor-pointer
  &:first-of-type
    @apply border-l-0 rounded-l-lg
  &:last-of-type
    @apply rounded-r-lg
  &:hover
    @apply bg-gray-100
    &.cursor-not-allowed
      @apply bg-transparent
</style>
