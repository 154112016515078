import { render, staticRenderFns } from "./Repayments.vue?vue&type=template&id=6c1a1c28&scoped=true&"
import script from "./Repayments.vue?vue&type=script&lang=js&"
export * from "./Repayments.vue?vue&type=script&lang=js&"
import style0 from "./Repayments.vue?vue&type=style&index=0&id=6c1a1c28&lang=sass&scoped=true&"
import style1 from "./Repayments.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c1a1c28",
  null
  
)

export default component.exports