<template>
  <div class="content">
    <div class="content_left">
      <div v-if="loading" class="flex items-center justify-center h-80">
        <i class="fa fa-spinner fa-spin text-center text-secondary text-2xl"></i>
      </div>
      <ul v-else>
        <li class="message_list" :class="'opacity-75'" v-for="inadimplente of inadimplentes.abaixo90Dias" :key="inadimplente.name" @click="selectMessage(inadimplente)">
          <div class="message_block" :class="Object.is(inadimplente,selectedMessageData) ? 'message_block_active' : ''" style="height: 6.5em ">
            <p class="message_title">{{inadimplente.nome}}</p>
            <p class="message_date">Total: {{inadimplente.valorParcelasEmAtraso | money}} ({{inadimplente.qtdeParcelasEmAtraso}} parcelas)</p>
            <p v-if="inadimplente.dataNegativacao && !inadimplente.dataExclusaoNegativacao" class="message_date">Negativado</p>

          </div>
        </li>
        <li class="message_list" :class="'opacity-75'" v-for="inadimplente of inadimplentes.acima90Dias" :key="inadimplente.name" @click="selectMessage(inadimplente)" >
          <div class="message_block" :class="Object.is(inadimplente,selectedMessageData) ? 'message_block_active' : ''" style="height: 6.5em ">
            <p class="message_title">{{inadimplente.nome}}</p>
            <p class="message_date">Total: {{inadimplente.valorParcelasEmAtraso | money}} ({{inadimplente.qtdeParcelasEmAtraso}} parcelas)</p>
            <p v-if="inadimplente.dataNegativacao && !inadimplente.dataExclusaoNegativacao" class="message_date">Negativado</p>
            <p v-if="inadimplente.numeroProcesso" class="message_date">Processo Nº {{inadimplente.numeroProcesso}}</p>
            <p v-if="inadimplente.numeroProcesso" class="message_date">Comarca {{inadimplente.comarca}}</p>

          </div>
        </li>
      </ul>
      <div :class="(inadimplentes.abaixo90Dias && inadimplentes.acima90Dias) && (inadimplentes.abaixo90Dias.length + inadimplentes.acima90Dias.length) > 5 ? 'sticky status' : 'absolute status'">
        <p class="status_title">Resumo</p>
        <span class="status_group">
          <p class="status_group_text">Tomadores em atraso: </p>
          <p class="status_group_text">{{inadimplentes.qtdeTomadoresEmAtraso || 0}}</p>
        </span>
        <span class="status_group">
          <p class="status_group_text">Parcelas em atraso: </p>
          <p class="status_group_text">{{inadimplentes.qtdeParcelasEmAtraso || 0}}</p>
        </span>
        <span class="status_group">
          <p class="status_group_text">Total da Inadimplência: </p>
          <p class="status_group_text">{{inadimplentes.valorTotalInadimplencia || 0 | money}}</p>
        </span>
      </div>
    </div>
    <div class="content_right">
      <div v-if="!loading && selectedMessageData" class="overflow-x-auto lg:overflow-x-hidden">
        <vuetable
          class="inadimplentes_table"
          :api-mode="false"
          :fields="header"
          :data="selectedMessageData.parcelas"
        >
        <template v-slot:numero="props">
          {{props.rowData.numero}}
        </template>
        <template v-slot:valor="props">
          {{props.rowData.valor | money}}
        </template>
        <template v-slot:diasAtraso="props">
          {{props.rowData.diasAtraso}}
        </template>
      </vuetable>
      </div>
      <div v-else-if="loading" class="h-full flex justify-center items-center">
        <i class="fas fa-spinner fa-spin text-secondary"></i>
      </div>
      <div v-else class="h-full flex justify-center items-center">Selecione um extrato para visualizá-lo</div>
    </div>
  </div>
</template>

<script>
import Inadimplentes from '@/services/Investor/Reports/Inadimplentes'
import moneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'inadimplentes',
  mixins: [moneyFilter],
  data () {
    return {
      inadimplentes: [],
      selectedMessageData: null,
      loading: true,
      header: [
        {
          name: '__slot:numero',
          title: 'Parcela'
        },
        {
          name: '__slot:valor',
          title: 'Prestação'
        },
        {
          name: '__slot:diasAtraso',
          title: 'Dias em atraso'
        }
      ]
    }
  },
  methods: {
    async getInadimplentes () {
      try {
        const response = (await Inadimplentes.getInadimplentes()).data.data
        this.inadimplentes = response
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    selectMessage (inadimplente) {
      if (Object.is(this.selectedMessage, inadimplente)) return
      this.loading = true
      this.selectedMessageData = inadimplente
      this.loading = false
    }
  },
  mounted () {
    this.getInadimplentes()
  }
}
</script>

<style lang="sass" scoped>
.content
  @apply w-full h-120 bg-white shadow border rounded-lg flex
  &_left
    @apply w-1/3 h-full border-r-2 overflow-auto
    @screen md
      @apply relative
  &_right
    @apply w-2/3 h-full p-3 relative overflow-y-auto
    @screen md
      @apply

.message
  &_block
    @apply h-20 border-b flex flex-col justify-center px-4
    @screen md
      @apply
    &:hover
      @apply cursor-pointer
      background-color: rgba(72, 27, 144, 0.1)
    &_active
      background-color: rgba(72, 27, 144, 0.2)
  &_list
    @apply opacity-75
    @screen md
      @apply
    &:first-child,
    &:first-child .message_block:hover,
    &:first-child .message_block_active
      @apply rounded-tl-lg
    &:last-child
      @apply rounded-bl-lg
  &_title
    @apply text-xs font-semibold
    @screen md
      @apply text-sm
  &_date
    @apply text-xss opacity-75
    @screen md
      @apply text-xs

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary

.status
  @apply bottom-0 border-b rounded-bl-lg p-4 h-32
  width: 99.5%
  background: #F6F6F6
  .status_title
    @apply text-primary font-semibold mb-2
  .status_group
    @apply flex justify-between
    .status_group_text
      @apply text-sm text-gray-600 opacity-75

</style>

<style lang="sass">
.inadimplentes_table
  @apply w-full bg-white shadow rounded-lg
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    &:nth-child(even)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    th
      @apply p-2 text-sm text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-center text-sm border border-gray-300 py-1
</style>
