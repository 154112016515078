<template>
  <div>
    <p class="title">Relatórios</p>
    <div class="row">
      <button @click="page = 'visao-geral'" :class="page === 'visao-geral' ? 'button_active' : ''" class="button">Visão Geral</button>
      <button @click="page = 'inadimplentes'" :class="page === 'inadimplentes' ? 'button_active' : ''" class="button">Extrato de Inadimplência</button>
      <button @click="page = 'repayments'" :class="page === 'repayments' ? 'button_active' : ''" class="button">Extrato de Repagamentos</button>
      <button @click="page = 'invoices'" :class="page === 'invoices' ? 'button_active' : ''" class="button">Meus boletos / PIX</button>
      <button @click="page = 'incomes'" :class="page === 'incomes' ? 'button_active' : ''" class="button">Informe de Rendimentos</button>

    </div>
    <p v-if="page === 'inadimplentes'" class="text-gray-600 text-xss opacity-75 my-2">Extrato consolidado de parcelas em atraso. São consideradas parcelas em atraso aquelas cujo o pagamento não foi identificado pelo banco 3 dias após o vencimento.</p>
    <p v-if="page === 'incomes'" class="text-gray-600 text-xss opacity-75 my-2">Comprovante de Rendimentos Pagos e de Imposto sobre a Renda Retido a Fonte.</p>
     <p v-if="page === 'invoices'" class="text-gray-600 text-xss opacity-75 my-2">A integralização dos seus aportes nas empresas em que registrou interesse é feita através do pagamento de boletos registrados em seu nome ou PIX e intermediados pela Iugu Pagamentos.
Uma cópia sempre é enviada ao e-mail do cadastro com as instruções para pagamento.</p>

    <div class="row">
      <visao-geral v-if="page === 'visao-geral'"/>
      <inadimplentes v-if="page === 'inadimplentes'"/>
      <repayments v-if="page === 'repayments'"/>
      <incomes v-if="page === 'incomes'"/>
      <invoices v-if="page === 'invoices'"/>

    </div>
  </div>
</template>

<script>
import Inadimplentes from '@/components/Investor/Reports/Inadimplentes'
import Repayments from '@/components/Investor/Reports/Repayments'
import Incomes from '@/components/Investor/Reports/Incomes'
import Invoices from '@/components/Investor/Reports/Invoices'
import VisaoGeral from '@/components/Investor/Reports/VisaoGeral'

export default {
  name: 'investor-reports',
  components: { Inadimplentes, Repayments, Incomes, Invoices, VisaoGeral },
  data () {
    return {
      page: 'visao-geral'
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  @apply font-bold text-xl text-secondary my-4 w-full
  @screen lg
    @apply text-2xl

.row
  @apply w-full flex flex-col
  @screen md
    @apply flex-row

.button
  @apply rounded-full bg-white text-gray-600 text-sm shadow-md h-6 px-2 mr-3 mb-2
  @screen lg
    @apply mb-0
  &:focus
    @apply outline-none
  &_active
    @apply bg-secondary text-white
  &_calendar
    @apply rounded-full bg-white text-gray-600 text-sm shadow-md w-8 h-8 mr-3
    &:focus
      @apply outline-none
    &_active
      @apply bg-secondary text-white

</style>
