<template>
  <div class="w-full flex flex-col lg:flex-row lg:flex-wrap">
    <view-file-modal v-if="blob" :blob="blob" blobType="application/pdf" @closeModal="blob = null"/>
    <div class="w-full lg:w-1/4 p-1" v-for="year of incomes" :key="year">
      <div class="card">
        <p class="card_title">{{`Ano calendário: ${year}`}}</p>
        <v-button class="lg:hidden" @click="openPdf(year)" size="sm" :loading="loadingYear === year" :disabled="loadingYear && loadingYear !== year">Download</v-button>
        <v-button class="hidden lg:block" @click="openPdf(year)" :loading="loadingYear === year" :disabled="loadingYear && loadingYear !== year">Download</v-button>
      </div>
    </div>
    <div v-if="loading" class="h-full w-full flex justify-center items-center">
      <i class="fas fa-spinner fa-spin text-2xl text-secondary"></i>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import ViewFileModal from '@/components/Modal/ViewFileModal'

import Incomes from '@/services/Investor/Reports/Incomes'

export default {
  name: 'info-incomes',
  components: { VButton, ViewFileModal },
  data () {
    return {
      loading: true,
      loadingYear: null,
      blob: null,
      incomes: []
    }
  },
  methods: {
    async openPdf (year) {
      try {
        this.loadingYear = year
        const response = (await Incomes.getIncome(year)).data
        //this.blob = await this.getBase64(response)
                this.downloadBlob(response,`Informe de Rendimentos ${year}.pdf`);

      } catch (error) {
        console.error(error)
      } finally {
        this.loadingYear = null
      }
    },
        async openExtractPdf (year) {
      try {
        this.loadingYear = year
        const response = (await Incomes.getExtract(year)).data

                this.downloadBlob(response,`Extrato Investimentos ${year}.pdf`);
        //this.blob = await this.getBase64(response)
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingYear = null
      }
    },
    downloadBlob(blob, name){
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.style.display = 'none';
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    }
  },
  async mounted () {
    try {
      const response = (await Incomes.getIncomes()).data.data
      this.incomes = response.sort().reverse()
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="sass" scoped>

.card
  @apply w-full h-32 bg-white shadow border rounded-lg inline-flex flex-col items-center justify-center
  @screen lg
    @apply h-40
  &_title
    @apply text-secondary font-bold text-sm
    @screen lg
      @apply text-base

</style>
